'use strict';

import 'waypoints/lib/jquery.waypoints.min';

export const inview = (() => {
    const $inview = $('.js-inview');
    // const $vPosPt = $('.js-viewPosPt');

    /**
     * init
     */
    const init = () => {
        $('.js-inview').each((index, element) => {
            const waypoint = new Waypoint({
                element: element,
                handler: direction => {
                    $(element).addClass('is-inview');
                },
                offset: $(element).data('offset') ? $(element).data('offset') : '90%'
            });
        });

        // $('.js-viewPosPt').each((index, element) => {
        //     const waypoint = new Waypoint({
        //         element: element,
        //         handler: direction => {
        //             if (direction === 'down') { //scroll down
        //                 $(element).addClass('is-inview');
        //             }
        //             else { //scroll up
        //                 $(element).removeClass('is-inview');
        //             }
        //         },
        //         offset: $(element).data('offset') ? $(element).data('offset') : '90%'
        //     });
        // });

        $(window).on('resize', () => {
            setTimeout(() => {
                Waypoint.refreshAll();
            }, 100);
        });
    };

    return {
        init: init
    };
})();
