'use strict';

/**
 * module
 */
import { scroll } from './_scroll';

/**
 * nav
 */
export const nav = (() => {
    const $toggle = $('.js-toggle-nav');
    const $hide = $('.js-hide-nav');
    const $menu = $('.js-menu');
    const $toTop = $('.js-pagetop');
    const $navG = $('.nav-global');
    let $topHeight = 0;
    let $wHeight = 0;
    let $wWidth = 0;
    let cr_pos = 0;
    let tcr_pos = 0;
    let pageHeight = 0;
    let stop_pos = 0;
    let btn_pos = 0;

    let start_pos = 0;
    let $href = null;

    $(window).on('load resize', function () {
        $wHeight = $(window).outerHeight();
        $wWidth = $(window).outerWidth();
        $topHeight = $('.js-top-h').innerHeight();
    });

    const toggle = () => {
        if (!$('body').hasClass('is-show-nav')) {
            tcr_pos = $(window).scrollTop();
            $('.js-wraper').css('top', '-' + tcr_pos + 'px')
        }

        setTimeout(() => {
            $('body').toggleClass('is-show-nav');
            $menu.toggleClass('is-open');

            if ($('body').hasClass('is-show-nav')) {
                window.scrollTo(0, 0);
            } else {
                window.scrollTo(0, tcr_pos);
            }
        }, 100);
    };

    const hide = () => {
        $('body').removeClass('is-show-nav');
        $menu.removeClass('is-open');
    };

    const scrJudge = () => {
        cr_pos = $(window).scrollTop();
        pageHeight = $(document).height();

        if ($wWidth <= 768) {
            btn_pos = $toTop.innerHeight();
        } else {
            btn_pos = 64;
        }
        stop_pos = pageHeight - $wHeight - $('.layout-footer').innerHeight() + btn_pos;

        if (cr_pos > $topHeight) {
            $toTop.addClass('is-visible');
        } else {
            $toTop.removeClass('is-visible');
        }
    }

    const touchHeight = () => {
        tcr_pos = $(window).scrollTop();
    }

    const init = () => {
        $toggle.on('click', event => {
            if ($wWidth <= 768) {
                toggle();
            }
        });

        $hide.on('click', event => {
            hide();
        });


        scrJudge();

        $(window).on('resize scroll', function () {
            setTimeout(() => {
                scrJudge();
            }, 10);
        });
    };

    return {
        init: init
    };
})();
