'use strict';

/**
 * justHeight
 */
export const justHeight = (() => {
    let winH = 0;
    let navH = 0;
    let headH = 0;
    let globalH = 0;
    let realH = 0;

    /**
     * init
     */
    const init = () => {
        adjust();

        $(window).on('resize scroll', function () {
            adjust();
        });
    };

    const adjust = () => {
        if ($(window).width() <= 768) {
            winH = window.innerHeight;
            navH = winH - $('.js-header').outerHeight();
            // globalH = navH - $('.js-navsub').outerHeight();
            $('.js-justH').height(navH);
            $('.js-justH-s').css('max-height', navH);
        } else {
            $('.js-justH-s').height('100%');
            $('.js-justH').css('max-height', '100%');
        }
    };

    // public
    return {
        init: init
    };
})();
