'use strict';

export const header = (() => {
    const $hd = $('.js-header');
    let $hdHeight = $hd.outerHeight();
    let $topHeight = $('.js-top-h').outerHeight();
    let st_pos = 0;
    let cr_pos = 0;
    let navbar_pos = 0;

    const scrJudge = () => {
        cr_pos = $(window).scrollTop();

        // $topHeight = $('.js-top-h').outerHeight();
        $topHeight = $('.js-header').outerHeight();
        if (cr_pos > $topHeight) {
            $('body').addClass('is-scrolled');
        } else if (!$('body').hasClass('is-show-nav')) {
            $('body').removeClass('is-scrolled');
        }
    }

    /**
     * init
     */
    const init = () => {
        scrJudge();

        $(window).on('scroll', () => {
            setTimeout(() => {
                scrJudge();
            }, 100);
        });
    };

    return {
        init: init
    };
})();
