'use strict';

export const toggle = (() => {
    let $wWidth = 0;

    const toggle = event => {
        const $this = $(event.currentTarget);

        $this.toggleClass('is-open');
        $this.next('.js-toggle-content').toggleClass('is-open');
    };

    const pre = () => {
        $wWidth = $(window).outerWidth();

        $('.js-toggle-content').each((index, element) => {
            const $this = $(element);
            if ($wWidth <= 768) {
                let tgH = $this.outerHeight();
                $this.css('margin-top', -tgH);
            } else {
                $this.css('margin-top', '0');
            }
        });
    }

    const init = () => {
        pre();

        $(window).on('resize', function () {
            setTimeout(() => {
                pre();
            }, 100);
        });

        $('.js-toggle').on('click', event => {
            toggle(event);
        });
    };

    return {
        init: init
    };
})();