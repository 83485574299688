'use strict';

export const faq = (() => {

    const toggle = event => {
        const $this = $(event.currentTarget);

        $this.toggleClass('is-open');
        $this.next('.js-faq-answer').toggleClass('is-open');
    };

    const pre = () => {
        $('.js-faq-answer').each((index, element) => {
            const $this = $(element);
            let tgH = $this.height();
            $this.css('margin-top', -tgH);
        });
    }

    const wait = (sec) => {
        var d = $.Deferred();
        setTimeout(() => {
            d.resolve();
        }, sec * 1000);
        return d.promise();
    }

    const init = () => {

        if ($('.list-faq').length) {
            $('body').addClass('in-preparation');
            let hash = location.hash;

            $.when(
                pre(),
                wait(0.5)
            ).done(() => {
                if (hash) {
                    $.when(
                        $("html,body").animate({ scrollTop: $(hash).offset().top - $('.layout-header').height() * 1.2 }, 0)
                    ).done(() => {
                        $('body').removeClass('in-preparation');
                        $(hash).click();
                    });
                } else {
                    $('body').removeClass('in-preparation');
                }
            });
        }

        pre();

        $(window).on('resize', function () {
            setTimeout(() => {
                pre();
            }, 100);
        });

        $('.js-faq-toggle').on('click', event => {
            toggle(event);
        });
    };

    return {
        init: init
    };
})();