'use strict';

import 'slick-carousel';

export const carousel = (() => {
    const $duration = 6000;
    const $speed = 600;

    /**
     * init
     */
    const init = () => {

        $('.js-carousel-visual').each((index, element) => {
            const $this = $(element);

            $this.on('init', function (event, slick, currentSlide) {
                $this.next('.js-carousel-page').children('.js-carousel-total').text(slick.slideCount);
                $this.next('.js-carousel-page').children('.js-carousel-current').text(slick.currentSlide + 1);

                if (slick.slideCount <= 1) {
                    $this.next('.js-carousel-page').addClass('is-disable');
                }
            });

            $this
                .not('.slick-initialized')
                .slick({
                    cssEase: 'ease',
                    autoplay: true,
                    autoplaySpeed: $duration,
                    speed: $speed,
                    adaptiveHeight: false,
                    infinite: true,
                    centerMode: false,
                    fade: true,
                    dots: false,
                    arrows: false,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    pauseOnDotsHover: false
                });

            $this.on('beforeChange', function (event, slick, currentSlide) {
                $('.js-carousel-bar').removeClass('is-first');
                $('.js-carousel-bar').removeClass('is-run');
            });

            $this.on('afterChange', function (event, slick, currentSlide) {
                $('.js-carousel-current').text(slick.currentSlide + 1);
                $('.js-carousel-bar').addClass('is-run');
            });

            $('.js-carousel-bar').css("animation-duration", ($duration + $speed) / 1000 + 's');

            // if ($('body').hasClass('is-loaded')) {
            //     $this.slick('slickPlay');
            //     $('.js-carousel-bar').addClass('is-ani');
            // } else {
            //     $this.slick('slickPause');
            //     $('.js-carousel-bar').removeClass('is-ani');
            // }
        });

        $('.js-carousel-bulletin')
            .not('.slick-initialized')
            .slick({
                speed: 1000,
                cssEase: 'ease',
                autoplay: true,
                autoplaySpeed: 5000,
                adaptiveHeight: false,
                infinite: true,
                dots: false,
                arrows: true,
                centerMode: false,
                pauseOnFocus: false,
                pauseOnHover: false,
                pauseOnDotsHover: false,
                prevArrow: '<div class="list-bulletin__arrow list-bulletin__arrow--prev"></div>',
                nextArrow: '<div class="list-bulletin__arrow list-bulletin__arrow--next"><div class="list-bulletin__arrow-icon"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="6" viewBox="0 0 30 6" class="icon-arrow"><path class="icon-arrow__path" d="M0 5h30v1h-30v-1zm25-4.244l4.243 4.244h-3z" /></svg></div></div>'
            });


        $('.js-carousel-scene')
            .not('.slick-initialized')
            .slick({
                speed: 1000,
                cssEase: 'ease',
                autoplay: true,
                autoplaySpeed: 5000,
                adaptiveHeight: false,
                infinite: true,
                dots: true,
                arrows: false,
                centerMode: true,
                pauseOnFocus: false,
                pauseOnHover: false,
                pauseOnDotsHover: false,
                variableWidth: true,
                responsive: [{
                    breakpoint: 769, settings: { //601px～1024pxでは3画像表示
                        centerMode: true,
                    }
                }]
            });
    };

    return {
        init: init
    };
})();
