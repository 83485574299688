'use strict';

/**
 * library
 */
import imagesLoaded from 'imagesloaded';
imagesLoaded.makeJQueryPlugin($);

import "bootstrap";

/**
 * module
 */
import { anchor } from './module/_anchor';
import { carousel } from './module/_carousel';
import { disabled } from './module/_disabled';
import { faq } from './module/_faq';
import { fitimg } from './module/_fitimg';
import { header } from './module/_header';
import { hover } from './module/_hover';
import { inview } from './module/_inview';
import { justHeight } from './module/_justHeight';
import { map } from './module/_map';
import { nav } from './module/_nav';
// import { barbaV2 } from './module/_barbaV2';
import { responsive } from './module/_responsive';
import { toggle } from './module/_toggle';


/**
 * main
 */
const main = (() => {
    /**
     * loaded
     */
    const loaded = () => {
        // anchorJudge();

        setTimeout(() => {
            $('body').addClass('is-loaded');
        }, 100);
    };

    /**
     * init
     */
    const init = () => {
        anchor.init();
        carousel.init();
        disabled.init();
        faq.init();
        fitimg.init();
        header.init();
        hover.init();
        inview.init();
        justHeight.init();
        map.init();
        nav.init();
        // barbaV2.init();
        responsive.init();
        toggle.init();

        //お問い合わせフォーム　クレジット非表示
        if ($('body > div:last-child').text().indexOf('Powered by') >= 0) {
            $('body > div:last-child').remove();
        }
    };

    const anchorJudge = () => {
        let urlHash = location.hash;
        const offset = $('.layout-header').height();

        if (urlHash) {
            const $target = $(urlHash);
            window.scrollTo(0, $target.offset().top - offset);
        }
    }

    // public
    return {
        init: init,
        loaded: loaded
    };
})();

$(() => {
    main.init();

    $('body')
        .imagesLoaded()
        .done(instance => {
            main.loaded();
        })
        .fail(() => {
            main.loaded();
        });
});
