'use strict';

/**
 * disabled
 */
export const disabled = (() => {
    const init = () => {
        const $disabled = $('.js-disabled');

        $disabled.on('click', event => {
            event.preventDefault();
        });
    };

    return {
        init: init
    };
})();
