'use strict';

/**
 * scroll
 */
export const scroll = (() => {
    const control = event => {
        event.preventDefault();
    };

    const off = () => {
        document.addEventListener('mousewheel', control, { passive: false });
        document.addEventListener('touchmove', control, { passive: false });
    };

    const on = () => {
        document.removeEventListener('mousewheel', control, { passive: false });
        document.removeEventListener('touchmove', control, { passive: false });
    };

    return {
        off: off,
        on: on
    };
})();
